<template>
    <div class="amap-page-container">
        <el-amap ref="map" vid="amapDemo" :amap-manager="amapManager" :center="center" :zoom="zoom" :plugin="plugin"
            class="amap-demo">
            <el-amap-marker v-for="(marker, index) in markerList" :key="marker.id" :position="marker.position"
                :content="getIcon(marker.type, marker.select, marker.remain)" :offset="[-21, -42]" :vid="index"
                :events="marker.events"></el-amap-marker>
            <el-amap-marker v-if="currentPositionShow" :position="currentPosition"
                content="<img src='https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%B0%B8%E7%A6%8F/%E5%B0%8F%E7%A8%8B%E5%BA%8F/current_location.png' style='width:42px;height:42px'/>"
                :offset="[-21, -42]" vid="currentLoaction"></el-amap-marker>

            <div class="detail-modal-wrap" v-if="modalShowText" >
                <div class="detail-modal-mask" @click.stop="detailModalClick"></div>
                <div class="detail-modal" >
                    <div class="contentTop">
                        <div class="contentLeft">
                            <img v-if="selectDevice.first_figure" :src="selectDevice.first_figure">
                            <div class="titleWrap" v-if="selectDevice.type!=3" :style="{
                                height:selectDevice.first_figure?'160px':'',
                            }">
                                <div v-if="!selectDevice.first_figure" class="detail-titleThree">{{ selectDevice.name }}</div>
                                <div v-if="selectDevice.first_figure" :class="audiosrc?'detail-titleTwo':'detail-title'">{{ selectDevice.name }}</div>
                                <div class="audioLine" v-if="audiosrc" :style="{ width: isPlayAudio ? '210px' : '80px' }">
                                    <!-- 按钮 -->
                                    <img @click="playAudio"
                                        src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E9%9F%B3%E9%A2%91%E6%92%AD%E6%94%BE.png"
                                        v-if="isPlayAudio" />
                                    <!-- <text>暂停</text> -->
                                    <img @click="playAudio"
                                        src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E9%9F%B3%E9%A2%91%E6%9A%82%E5%81%9C.png"
                                        v-if="!isPlayAudio" />
                                    <div class="tiptitle">{{ isPlayAudio ? '讲解中…' : '讲解' }}</div>
                                    <!-- <text>播放</text> -->
                                    <div class="slider" v-if="isPlayAudio">
                                        <van-slider v-model="audioTime" @change="sliderChange" inactive-color="#E0E0E0"
                                            button-size="0px" bar-height="4px" active-color="#89B9F3" disabled />
                                    </div>

                                    <div class="audioFont" v-if="false">
                                        {{ showTime1 }}/<span class="audioFont2">{{ showTime2 }}</span>
                                    </div>
                                </div>
                                <div class="distanceWrap" v-if="selectDevice.type==2">
                                    <div style="display: flex; align-items: center">
                                        <span class="distance">距我{{ selectDevice.juli }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="titleWrapTwo" v-if="selectDevice.type==3">
                                <div class="detail-titleThree">{{ selectDevice.name }}</div>
                            </div>
                        </div>
                        <!-- <div class="detail-close" @click.stop="detailModalClick"></div> -->
                    </div>
                    <div class="detail-address" v-if="selectDevice.type==1||selectDevice.type==4||selectDevice.type==12||selectDevice.type==5||selectDevice.type==6||selectDevice.type==3||selectDevice.type==11">
                        <div style="display: flex; align-items: center">
                            <span class="distance">距我{{ selectDevice.juli }}</span>
                            <span class="addressTitle">{{ selectDevice.addressText }}</span>
                        </div>
                        <!-- <img
                            src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%8F%B3%E8%BE%B9%E7%AE%AD%E5%A4%B4.png" /> -->
                    </div>
                    <div class="carportWrap" v-if="selectDevice.type==2">
                        <div>
                            总停车位：{{ selectDevice.count ? selectDevice.count : '未知' }}
                        </div>
                        <div :style="{
                            color:selectDevice.ifMianfei?'#DE2323':'#32BE33'
                        }">
                            {{ selectDevice.ifMianfei ? '收费' : '免费' }}
                        </div>
                        <!-- <div class="detail-parking-desc">剩余停车位：{{ selectDevice.free ? selectDevice.free : '未知' }}</div> -->
                    </div>
                    <!-- <div class="audioLine" v-if="audiosrc" :style="{ width: isPlayAudio ? '324px' : '80px' }">
                        <img @click="playAudio"
                            src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E9%9F%B3%E9%A2%91%E6%92%AD%E6%94%BE.png"
                            v-if="isPlayAudio" />
                        <img @click="playAudio"
                            src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E9%9F%B3%E9%A2%91%E6%9A%82%E5%81%9C.png"
                            v-if="!isPlayAudio" />
                        <div class="tiptitle">{{ isPlayAudio ? '讲解中…' : '讲解' }}</div>
                        <div class="slider" v-if="isPlayAudio">
                            <van-slider v-model="audioTime" @change="sliderChange" inactive-color="#E0E0E0"
                                button-size="0px" bar-height="4px" active-color="#89B9F3" disabled />
                        </div>

                        <div class="audioFont" v-if="false">
                            {{ showTime1 }}/<span class="audioFont2">{{ showTime2 }}</span>
                        </div>
                    </div> -->
                    <div class="describetion" v-if="selectDevice.describetion">
                        {{ selectDevice.describetion }}
                    </div>
                    <div class="btnGroupStyle" v-if="selectDevice.type==1||selectDevice.type==4||selectDevice.type==5||selectDevice.type==6||selectDevice.type==11">
                        <div class="detailBtn" @click="goToDetailPage(selectDevice)">详情</div>
                        <div class="navigation" @click="gotoLocation(selectDevice)">到这去</div>
                    </div>
                    <div class="btnGroupStyle" v-if="selectDevice.type==2||selectDevice.type==3||selectDevice.type==12">
                        <div class="navigationTwo" @click="gotoLocation(selectDevice)">到这去</div>
                    </div>
                </div>
            </div>
            <div @click="gotoSearch" class="serchbox">
                <van-icon
                    name="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E6%90%9C%E7%B4%A201%402x.png"
                    size="15px" />
                <span>输入关键词进行搜索</span>
            </div>
        </el-amap>

        <div class="bottomLine" v-if="!ifReturnList && !selectDevice.id">
            <div class="bottom-title">周边</div>
            <div class="bottom-icon-wrap">
                <div class="iconArea" :class="selectedType === 1 ? 'iconAreaSelect' : ''" @click="changeSelectType(1)">
                    <img src="./assets/icon1.png" />
                    <span>景点</span>
                </div>
                <div class="iconArea" :class="selectedType === 4 ? 'iconAreaSelect' : ''" @click="changeSelectType(4)">
                    <img src="./assets/icon2.png" />
                    <span>餐饮美食</span>
                </div>
                <div class="iconArea" :class="selectedType === 5 ? 'iconAreaSelect' : ''" @click="changeSelectType(5)">
                    <img src="./assets/icon3.png" />
                    <span>酒店民宿</span>
                </div>
                <div class="iconArea" :class="selectedType === 6 ? 'iconAreaSelect' : ''" @click="changeSelectType(6)">
                    <img src="./assets/icon4.png" />
                    <span>购物场所</span>
                </div>
                <div class="iconArea" :class="selectedType === 11 ? 'iconAreaSelect' : ''" @click="changeSelectType(11)">
                    <img src="./assets/icon5.png" />
                    <span>休闲娱乐</span>
                </div>
            </div>
        </div>

        <div class="rightLine">
            <div class="right-item" :class="selectedType === 2 ? 'right-item-select' : ''" @click="changeSelectType(2)">
                <img src="./assets/icon6.png" v-if="selectedType !== 2" />
                <img src="./assets/select-icon6.png" v-else />
                <span>停车场</span>
            </div>
            <div class="right-item" :class="selectedType === 3 ? 'right-item-select' : ''" @click="changeSelectType(3)">
                <img src="./assets/icon7.png" v-if="selectedType !== 3" />
                <img src="./assets/select-icon7.png" v-else />
                <span>公厕</span>
            </div>
            <div class="right-item" :class="selectedType === 12 ? 'right-item-select' : ''" @click="changeSelectType(12)">
                <img src="./assets/icon8.png" v-if="selectedType !== 12" />
                <img src="./assets/select-icon8.png" v-else />
                <span>加油站</span>
            </div>
            <div class="right-item" :class="trafficLayer ? 'right-item-select' : ''" @click="trafficLayerClick">
                <img src="./assets/icon9.png" v-if="!trafficLayerClick" />
                <img src="./assets/select-icon9.png" v-else />
                <span>路况</span>
            </div>
        </div>
        <div class="right-location" @click="goToCenter">
            <img src="./assets/current-location.png" />
        </div>
        <audio :src="audiosrc" ref="audio" @timeupdate="viodoUpdate" @canplay="initialization" preload="true"></audio>
    </div>
</template>
<script
    type="text/javascript"
    src="https://unpkg.com/@dcloudio/uni-webview-js@0.0.2/index.js"
></script>
<script
    type="text/javascript"
    src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"
></script>
<script>
import axios from 'axios';
import { Notify, Slider, Icon } from 'vant';
  // NPM 方式
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
    key: 'bf8e7cb2ae43312d6b6dafae4efeb62d',
    plugin: [
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Geolocation',
        'AMap.Driving',
        'AMap.ImageLayer',
    ],
    uiVersion: '1.0.11', // 版本号
    v: '1.4.4',
});
import { AMapManager } from 'vue-amap';
let amapManager = new AMapManager();
let trafficLayer = null;
const position = [120.376215, 30.455996];

export default {
    components: {
        [Slider.name]: Slider,
        [Icon.name]: Icon,
    },
    data: function () {
        const this_ = this;
        const token = this_.$route.query.biaotoken;
        const contentId = this_.$route.query.contentId || null;
        const topicSymbol = this_.$route.query.topicSymbol || null;
        const latitude = this_.$route.query.latitude || null;
        const longitude = this_.$route.query.longitude || null;
        const tenantId = this_.$route.query.tenantId || null;
        const title = '科同村';
        const lat = this_.$route.query.userlat || 0;
        const lon = this_.$route.query.userlon || 0;
        const id = this_.$route.query.id || null;
        const topicSymbolIndex = this_.$route.query.topicSymbolIndex || null;
        return {
            token,
            topicSymbolIndex,
            id,
            audioTime: '',
            audiosrc: '',
            isPlayAudio: false,
            audioSeek: 0,
            audioDuration: 0,
            showTime1: '00:00',
            showTime2: '00:00',
            audioColor: '#1677FF',
            latitude,
            longitude,
            isshow: false,
            // 当前定位点
            currentLocation: [],
            // 选中设备详情
            selectDevice: {},
            contentId: contentId,
            tenantId: tenantId,
            topicSymbol: topicSymbol,
            ifReturnList: contentId ? true : false,
            // 选中的类型
            selectedType: 1,
            amapManager,
            zoom: 12,
            trafficLayer:false,
            center: position,
            currentPosition: [],
            currentPositionShow: false,
            plugin: [],
            mockData: [],
            markers: [
                {
                    position: position,
                },
            ],
            userLocation: {
                lat: lat,
                lng: lon,
            },
            allMarkers: [
                {
                    position: [119.4333, 29.546884],
                    type: 1,
                    name: '景点',
                },
                {
                    position: [119.6333, 29.53884],
                    type: 2,
                    name: '停车场',
                },
                {
                    position: [119.64545, 29.534884],
                    type: 3,
                    name: '卫生间',
                },
                {
                    position: [119.343232, 29.46884],
                    type: 4,
                    name: '餐饮美食',
                },
                {
                    position: [119.49885, 29.596884],
                    type: 5,
                    name: '酒店民宿',
                },
                {
                    position: [119.4783, 29.5767484],
                    type: 6,
                    name: '购物场所',
                },
                {
                    position: [119.4783, 29.5767484],
                    type: 7,
                    name: '休闲娱乐',
                },
            ],
            markerList: [],
            location1: require('./assets/marker1.png'),
            location2: require('./assets/marker6.png'),
            location3: require('./assets/marker7.png'),
            location4: require('./assets/marker2.png'),
            location5: require('./assets/marker3.png'),
            location6: require('./assets/marker4.png'),
            location11: require('./assets/marker5.png'),
            location12: require('./assets/marker8.png'),
            locationSelected1: require('./assets/select-marker1.png'),
            locationSelected2: require('./assets/select-marker6.png'),
            locationSelected3: require('./assets/select-marker7.png'),
            locationSelected4: require('./assets/select-marker2.png'),
            locationSelected5: require('./assets/select-marker3.png'),
            locationSelected6: require('./assets/select-marker4.png'),
            locationSelected11: require('./assets/select-marker5.png'),
            locationSelected12: require('./assets/select-marker8.png'),
            redCircle: require('./img/redCircle.png'),
            bigBlackCircle: require('./img/bigBlackCircle.png'),
            smallBlackCircle: require('./img/smallBlackCircle.png'),
            detailIconNew: require('./img/detail.png'),
            daohangIcon: require('./img/daohang.png'),
            detailIcon: require('./img/detailIcon.png'),
            tringle: require('./img/tringle.png'),
            texts: [
            {
                position: position,
                text: title,
                offset: [0, -50],
            },
            ],
            shiyan: '默认',
            textList: [],
            textContent: '',
            textOffset: [0, -36],
            modalCanHide:false,
            modalShowText: false
        };
    },
    mounted() {
        // 添加图层
        this.$nextTick(() => {
            this.initialization();
            const timer = setTimeout(()=>{
            clearTimeout(timer)
            let o = amapManager.getMap();
            trafficLayer = new AMap.TileLayer.Traffic({
                        zIndex: 10
                    });
                    trafficLayer.setMap(o);
                    trafficLayer.hide();
            },1500)
        });
    },
    created() {
        let latitude=window.localStorage.getItem(latitude)
        let longitude=window.localStorage.getItem(longitude)
        if(latitude&&longitude){
            this.latitude=latitude;
            this.longitude=longitude
        }
        if (this.ifReturnList) {
            this.getOneMarker();
        } else {
            this.selectedType = parseInt(this.topicSymbolIndex) || 1;
            this.pushMarkers();
        }
        window.localStorage.setItem('biaotoken',this.token)
    },
    methods: {
        gotoAttractions(){
            if(this.selectDevice.topicSymbol=='min-attractions-detail'&&this.selectDevice.id){
                wx.miniProgram.navigateTo({ url: `/culturalTravel/scenicSpot/detail?id=${this.selectDevice.id}` });
            }
        },
        gotoSearch() {
            this.$router.push({ name: 'ketongSearchDetail',query:{latitude:this.latitude,longitude:this.latitude,userlat:this.userLocation.lat,userlon:this.userLocation.lng} });
        },
        initialization() {
            let that = this;
            if (this.audiosrc && this.audiosrc.length > 0) {
            //设置src
            const timer = setTimeout(() => {
                //延时获取音频真正的duration
                var duration = this.$refs.audio.duration;
                var min = parseInt(duration / 60);
                var sec = parseInt(duration % 60);
                if (min.toString().length == 1) {
                min = `0${min}`;
                }
                if (sec.toString().length == 1) {
                sec = `0${sec}`;
                }
                that.audioDuration = this.$refs.audio.duration;
                that.showTime2 = `${min}:${sec}`;
                clearTimeout(timer);
                console.log(`${min}:${sec}`);
            }, 1000);
            }
        },
        rad(d) {
            return (d * Math.PI) / 180.0;
        },
        viodoUpdate() {
            const that = this;
            console.log();
            if (that.$refs.audio.currentTime / that.$refs.audio.duration == 1) {
                // 播放完成
                that.audioTime = 0;
                that.showTime1 = '00:00';
                that.isPlayAudio = false;
            } else {
                var per =
                    (that.$refs.audio.currentTime / that.$refs.audio.duration) * 100;
                that.audioTime = per;
                that.showTime1 = that.showTimeFormat(that.$refs.audio.currentTime);
            }
        },
        showTimeFormat(duration) {
            let min = parseInt(duration / 60);
            let sec = parseInt(duration % 60);
            if (min.toString().length == 1) {
                min = `0${min}`;
            }
            if (sec.toString().length == 1) {
                sec = `0${sec}`;
            }
            return `${min}:${sec}`;
        },
        playAudio() {
            //获取播放状态和当前播放时间
            let isPlayAudio = this.isPlayAudio;
            this.$refs.audio.pause();
            //更改播放状态
            this.isPlayAudio = !isPlayAudio;
            if (!isPlayAudio) {
                //如果在暂停，获取播放时间并继续播放
                this.$refs.audio.play();
            }
        },
        sliderChange(value) {
            //获取进度条百分比
            this.audioTime = value;
            let duration = this.$refs.audio.duration;
            //根据进度条百分比及歌曲总时间，计算拖动位置的时间
            value = parseInt((value * duration) / 100);
            //更改状态
            this.audioSeek = value;
            this.isPlayAudio = true;
            //调用seek方法跳转歌曲时间
            this.$refs.audio.currentTim = value;
            //播放歌曲
            this.$refs.audio.play();
        },
        goToCenter() {
            this.currentPositionShow = false;
            AMap.plugin('AMap.Geolocation', () => {
            var geolocation = new AMap.Geolocation({
                enableHighAccuracy: true, // 是否使用高精度定位，默认：true
                timeout: 10000, // 设置定位超时时间，默认：无穷大
                offset: [10, 20], // 定位按钮的停靠位置的偏移量
                zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                position: 'RB', //  定位按钮的排放位置,  RB表示右下
            });
    
            geolocation.getCurrentPosition((status, result) => {
                console.log(status, 'status', result);
                if (status == 'complete') {
                    let center = [...this.center];
                    let { lat, lng } = result.position;
                    if (center[0] == lng) {
                        lng = lng - 0.000001;
                    }
                    if (center[1] == lat) {
                        lat = lat - 0.000001;
                    }
                    this.center = [lng, lat];
                    console.log(this.center, 'this.center');
                } else {
                    if (this.center === position) {
                        this.center = [position[0] - 0.000001, position[1] - 0.000001];
                    } else {
                        this.center = position;
                    }
                }
                this.currentPosition = [...this.center];
                this.currentPositionShow = true;
            });
            });
        },
        getCenterByCode() {
            const this_ = this;
            const params = {
                keywords: this.tenantId.slice(0, 6),
                key: 'b730f143b581509e91e4e00e70d11381',
            };
            axios.defaults.headers = {};
            axios
            .get('https://restapi.amap.com/v3/config/district', { params: params })
            .then((res) => {
                let center = res.data.districts[0].center.split(',');
                console.log(center);
                const centerTrue = [];
                center.forEach((item) => {
                    centerTrue.push(parseFloat(item));
                });
                this_.center = [...centerTrue];
            });
        },
        getOneMarker() {
            const this_ = this;
            const contentId = this_.contentId;
            const topicSymbol = this_.topicSymbol;
            const tenantId = this_.tenantId;
            const mockData = this_.$api
            .getContentDetail({
                contentId: contentId || '',
                topicSymbol: topicSymbol,
                tenantId: 330481101000,
            })
            .then((res) => {
                if (res.data.code === 10000) {
                const item = this_.mockData;
                console.log(item);
                // 放到点中去
                const markers = [];
                if (topicSymbol === 'min-souvenir-detail') {
                    jingduList += parseFloat(item.content.location[0]) || 0;
                    weiduList += parseFloat(item.content.location[1]) || 0;
                    const marker = {
                        juli: this.getDistance(
                            parseFloat(item.content.location[1]),
                            parseFloat(item.content.location[0]),
                            parseFloat(this.userLocation.lat),
                            parseFloat(this.userLocation.lng)
                        ),
                        id: item.id,
                        addressText:
                        item.content.addressText ||
                        item.content.location_text ||
                        item.content.address_text,
                        topicSymbol: topicSymbolItem,
                        position: item.content.location,
                        type: this_.getType(topicSymbolItem),
                        name: item.title,
                        select: false,
                        audiosrc: item.content.introduction_audio,
                        headPic: item.content.detail_pic,
                        describetion: item.content.describetion
                        ? item.content.describetion
                        : '',
                        first_figure:item.content.first_figure
                    };
                    markers.push(marker);
                    this_.markerList = markers;
                    // 更新地图中心点
                    this_.center = item.content.purchase_point;
                    // 设置景点文字
                    this_.lookText(marker);
                } else {
                    jingduList += parseFloat(item.content.location[0]) || 0;
                    weiduList += parseFloat(item.content.location[0]) || 0;
                    const marker = {
                        juli: this.getDistance(
                            parseFloat(item.content.location[0]),
                            parseFloat(item.content.location[0]),
                            parseFloat(this.userLocation.lat),
                            parseFloat(this.userLocation.lng)
                        ),
                        id: item.id,
                        addressText:
                        item.content.addressText ||
                        item.content.location_text ||
                        item.content.address_text,
                        topicSymbol: topicSymbolItem,
                        position: item.content.location,
                        type: this_.getType(topicSymbolItem),
                        name: item.title,
                        select: false,
                        audiosrc: item.content.introduction_audio,
                        headPic: item.content.detail_pic,
                        describetion: item.content.describetion
                        ? item.content.describetion
                        : '',
                        first_figure:item.content.first_figure
                    };
                    markers.push(marker);
                    this_.markerList = markers;
                    // 更新地图中心点
                    this_.center = item.content.location;
                    this_.zoom = 13;
                    // 设置景点文字
                    this_.lookText(marker);
                }
                } else {
                    Notify({ type: 'warning', message: res.data.msg });
                }
            });
        },
        trafficLayerClick(){
            this.trafficLayer=!this.trafficLayer
            if (this.trafficLayer) {
                trafficLayer && trafficLayer.show();
            }else {
                trafficLayer && trafficLayer.hide();
            }
        },
        pushMarkers() {
            let this_ = this;
            let markers = [];
            let index = 0;
            const type = this_.selectedType;
            const topicSymbol = this_.getTopicSymbol(parseInt(type));
            let parkingList = [];
            topicSymbol.forEach((topicSymbolItem) => {
            this_.$api
                .getContentByPage({
                    page: 1,
                    size: 1000,
                    topicSymbol: topicSymbolItem,
                    tenantId: 330481101000,
                })
                .then((res) => {
                if (res.data.code === 10000) {
                    const list = res.data.data.records;
                    console.log(res.data, 1111222);
                    if (list.length === 0) {
                        this_.getCenterByCode();
                        return;
                    }
                    // 获取经度数组和纬度数组
                    let jingduList = 0;
                    let dianjimarker=''
                    let weiduList = 0;
                    list.forEach((item) => {
                    console.log(item, 111122222);
                    jingduList += parseFloat(item.content.location[0]) || 0;
                    weiduList += parseFloat(item.content.location[1]) || 0;
                    const marker = {
                        juli: this.getDistance(
                            parseFloat(item.content.location[1]),
                            parseFloat(item.content.location[0]),
                            parseFloat(this.userLocation.lat),
                            parseFloat(this.userLocation.lng)
                        ),
                        id: item.id,
                        addressText:
                        item.content.addressText ||
                        item.content.location_text ||
                        item.content.address_text,
                        topicSymbol: topicSymbolItem,
                        position: item.content.location,
                        type: this_.getType(topicSymbolItem),
                        name: item.title,
                        select: false,
                        audiosrc: item.content.introduction_audio,
                        headPic: item.content.detail_pic,
                        describetion: item.content.describetion
                        ? item.content.describetion
                        : '',
                        first_figure:item.content.first_figure
                    };
                    if (topicSymbol == 'min-park-lot-list') {
                        marker.count = item.content.parking_amount;
                        marker.remain = null;
                        marker.ifMianfei =
                        item.content.parking_id === 1 ? true : false;
                    }
                    marker.events = {
                        click: (e) => {
                            console.log(e,'测试点击')
                            marker.select = true;
                            this_.lookText(marker,e);
                        },
                    };
                    if (marker.id == this.id) {
                        dianjimarker=marker
                    }
                    markers.push(marker);
                    });
                    jingduList = parseFloat(jingduList / list.length).toFixed(6);
                    weiduList = parseFloat(weiduList / list.length).toFixed(6);
                    this_.center = [jingduList, weiduList];
                    if(dianjimarker){
                        this_.lookText(dianjimarker);
                    }
                    this_.zoom = 13;
                } else {
                    Notify({ type: 'warning', message: res.data.msg });
                }
                });
            });
            this_.markerList = markers;
        },
        changeSelectType(type) {
            // 为了防止从搜索页面跳到这里弹窗多次打开所以类型切换直接把id置空就不会自动弹出来弹窗了
            this.id = '';
            if (type == 18) {
                this.selectedType = type;
                // wx.miniProgram.postMessage({ data: {type:'luxian'} });
                // wx.miniProgram.navigateTo({ url: '/pages/traffic/index' });
            } else {
                this.selectDevice = {};
                this.modalCanHide =false
                this.modalShowText = false
                this.textList = [];
                this.textContent = '';
                this.selectedType = type;
                this.pushMarkers();
        
            }
        },
        getType(topicSymbol) {
            let type = '';
            switch (topicSymbol) {
            case 'min-attractions-detail':
                type = 1;
                break;
            case 'min-park-lot-list':
                type = 2;
                break;
            case 'min-toilet-list':
                type = 3;
                break;
            case 'min-catering-detail':
                type = 4;
                break;
            case 'min-food-detail':
                type = 4;
                break;
            case 'min-hotel-detail':
                type = 5;
                break;
            case 'min-shop-detail':
                type = 6;
                break;
            case 'min-souvenir-detail':
                type = 7;
                break;
            case 'min-entertainment-detail':
                type = 11;
                break;
            case 'min-gasStation-detail':
                type = 12;
                break;
            }
            return type;
        },
        getDistance(lat1, lng1, lat2, lng2) {
            console.log(lat1, lng1, lat2, lng2,'数据')
            let EARTH_RADIUS = 6378.137;
            let radLat1 = this.rad(lat1);
            let radLat2 = this.rad(lat2);
            let a = radLat1 - radLat2;
            let b = this.rad(lng1) - this.rad(lng2);
            let s =
            2 *
            Math.asin(
                Math.sqrt(
                Math.pow(Math.sin(a / 2), 2) +
                    Math.cos(radLat1) *
                    Math.cos(radLat2) *
                    Math.pow(Math.sin(b / 2), 2)
                )
            );
            s = s * EARTH_RADIUS;
            s = parseInt(s * 1000); //乘以1000是换算成米
            s = s < 1000 ? s + '米' : (s / 1000).toFixed(1) + '公里';
            return s;
        },
        getTopicSymbol(type) {
            let topicSymbol = '';
            switch (type) {
            case 1:
                topicSymbol = ['min-attractions-detail'];
                break;
            case 2:
                topicSymbol = ['min-park-lot-list'];
                break;
            case 3:
                topicSymbol = ['min-toilet-list'];
                break;
            case 4:
                topicSymbol = ['min-catering-detail', 'min-food-detail'];
                break;
            case 5:
                topicSymbol = ['min-hotel-detail'];
                break;
            case 6:
                topicSymbol = ['min-shop-detail'];
                break;
            case 11:
                topicSymbol = ['min-entertainment-detail'];
                break;
            case 12:
                topicSymbol = ['min-gasStation-detail'];
                break;
            }
            return topicSymbol;
        },
        getTypeName(type) {
            let name = '';
            switch (type) {
            case 1:
                name = '景点';
                break;
            case 2:
                name = '停车场';
                break;
            case 3:
                name = '公厕';
                break;
            case 4:
                name = '餐饮美食';
                break;
            case 5:
                name = '酒店民宿';
                break;
            case 6:
                name = '购物场所';
                break;
            case 11:
                name = '休闲娱乐';
                break;
            case 12:
                name = '加油站';
                break;
            }
            return name;
        },
        // 获取背景图
        getBackIconUrl(amount) {
            if (!amount) {
                return null;
            }
            console.log(amount);
            amount = Number(amount);
            if (amount == 0) {
                return './img/redCircle.png';
            }
            if (amount > 0 && amount < 100) {
                return this.smallBlackCircle;
            }
            if (amount > 100) {
                return './img/bigBlackCircle.png';
            }
        },
        getIcon(type, select, amount) {
            const this_ = this;
            let pic = '';
            switch (type) {
            case 1:
                if (select) {
                pic = `<img src=${this_.locationSelected1} style='width:42px;height:42px'/>`;
                } else {
                pic = `<img src=${this_.location1} style='width:42px;height:42px'/>`;
                }
                break;
            case 2:
                if (amount) {
                if (select) {
                    pic = `<div style='width: 22px;height: 43px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;'><div v-if=${amount}  style='width:100%;height:17.5px;display: flex;justify-content: center;align-items: flex-start;position: relative;'><img src=${
                    amount == 0
                        ? this_.redCircle
                        : amount > 99
                        ? this_.bigBlackCircle
                        : this_.smallBlackCircle
                    } style='width:${
                    String(amount).length == 3 ? '15px' : '12px'
                    };height:${
                    String(amount).length == 3 ? '17px' : '14px'
                    };position: absolute;top: ${
                    String(amount).length == 3 ? '0px' : '4px'
                    };left: ${
                    String(amount).length == 3 ? '3.5px' : '5px'
                    };' /><span style='font-size: 7px;color:#FFFFFF;position: absolute;top: ${
                    String(amount).length == 3 ? '-1px' : '1px'
                    };left: ${
                    String(amount).length == 3
                        ? '6.4px'
                        : String(amount).length == 2
                        ? '7.4px'
                        : '9px'
                    }'>${amount}</span></div><img src=${
                    this_.locationSelected2
                    } style='width:42px;height:42px'/></div>`;
                } else {
                    pic = `<div style='width: 22px;height: 43px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;'><div v-if=${amount}  style='width:100%;height:17.5px;display: flex;justify-content: center;align-items: flex-start;position: relative;'><img src=${
                    amount == 0
                        ? this_.redCircle
                        : amount > 99
                        ? this_.bigBlackCircle
                        : this_.smallBlackCircle
                    } style='width:${
                    String(amount).length == 3 ? '15px' : '12px'
                    };height:${
                    String(amount).length == 3 ? '17px' : '14px'
                    };position: absolute;top: ${
                    String(amount).length == 3 ? '0px' : '4px'
                    };left: ${
                    String(amount).length == 3 ? '3.5px' : '5px'
                    };' /><span style='font-size: 7px;color:#FFFFFF;position: absolute;top: ${
                    String(amount).length == 3 ? '-1px' : '1px'
                    };left:${
                    String(amount).length == 3
                        ? '6.4px'
                        : String(amount).length == 2
                        ? '7.4px'
                        : '9px'
                    }'>${amount}</span></div><img src=${
                    this_.location2
                    } style='width:42px;height:42px'/></div>`;
                }
                } else {
                if (select) {
                    pic = `<img src=${this_.locationSelected2} style='width:42px;height:42px'/>`;
                } else {
                    pic = `<img src=${this_.location2} style='width:42px;height:42px'/>`;
                }
                }
    
                break;
            case 3:
                if (select) {
                pic = `<img src=${this_.locationSelected3} style='width:42px;height:42px'/>`;
                } else {
                pic = `<img src=${this_.location3} style='width:42px;height:42px'/>`;
                }
                break;
            case 4:
                if (select) {
                pic = `<img src=${this_.locationSelected4} style='width:42px;height:42px'/>`;
                } else {
                pic = `<img src=${this_.location4} style='width:42px;height:42px'/>`;
                }
                break;
            case 5:
                if (select) {
                pic = `<img src=${this_.locationSelected5} style='width:42px;height:42px'/>`;
                } else {
                pic = `<img src=${this_.location5} style='width:42px;height:42px'/>`;
                }
                break;
            case 6:
                if (select) {
                pic = `<img src=${this_.locationSelected6} style='width:42px;height:42px'/>`;
                } else {
                pic = `<img src=${this_.location6} style='width:42px;height:42px'/>`;
                }
                break;
            case 11:
                if (select) {
                pic = `<img src=${this_.locationSelected11} style='width:42px;height:42px'/>`;
                } else {
                pic = `<img src=${this_.location11} style='width:42px;height:42px'/>`;
                }
                break;
            case 12:
                if (select) {
                pic = `<img src=${this_.locationSelected12} style='width:42px;height:42px'/>`;
                } else {
                pic = `<img src=${this_.location12} style='width:42px;height:42px'/>`;
                }
                break;
            }
            return pic;
        },
        lookText(marker,e) {
            
            console.log('触发了',marker);
            const this_ = this;
            const list = this_.markerList;
            list.forEach((item) => {
                if (item.id == marker.id) {
                    item.select = true;
                } else {
                    item.select = false;
                }
            });
            this.modalShowText = true
            this.selectDevice = marker
            // console.log(this.selectDevice,'selectDeviceselectDeviceselectDevice',this.modalShowText);
            setTimeout(() => {
                this.modalCanHide = true
            }, 30);

            this.audiosrc = '';
            this.audioTime = '';
            this.audiosrc = marker.audiosrc;

            this.initialization();
            this.isshow = true;
            setTimeout(() => {
            this.isshow = false;
            }, 1000);
            this.center = [marker.position[0] - 0.000001, marker.position[1] - 0.000001];
        },
        goToDetailPage(marker) {
            const that = this;
            console.log(marker);
            const url = that.getPageUrl(marker.topicSymbol);
            if (!that.ifReturnList) {
                wx.miniProgram.navigateTo({
                    url: `${url}?id=${marker.id}`,
                    events: {
                    // 为指定事件添加一个监听器，获取被打开页面传送到当前页面的数据
                    acceptDataFromOpenedPage: function (data) {
                        console.log(data);
                    },
                    someEvent: function (data) {
                        console.log(data);
                    },
                    },
                    success: function (res) {
                        // 通过eventChannel向被打开页面传送数据
                        console.log(res);
                    },
                });
            }
        },
        // 规划路径
        gotoLocation(selectDevice) {
            const this_ = this;
            const latitude = this_.latitude;
            const longitude = this_.longitude;
            let o = amapManager.getMap();
            let driving = new AMap.Driving({
            map: o,
            // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
            policy: AMap.DrivingPolicy.LEAST_TIME,
            });
    
            if (!longitude) {
            Notify({ type: 'warning', message: '未授权定位或定位失败' });
            return false;
            }
            // 打开小程序的规划路线
            wx.miniProgram.navigateTo({
                url: `/pages/mapViewChoose/index?longitude=${selectDevice.position[0]}&latitude=${selectDevice.position[1]}&title=${selectDevice.name}`,
            });
        },
        // 清除路径
        clearLocation() {
            let map = amapManager.getMap();
            map.clearMap();
        },
        // 获取页面
        getPageUrl(topicSymbol) {
            let url = '';
            switch (topicSymbol) {
            case 'min-attractions-detail':
                url = '/culturalTravel/scenicSpot/detail';
                break;
            case 'min-catering-detail':
                url = '/culturalTravel/catering/detail';
                break;
            case 'min-food-detail':
                url = '/culturalTravel/catering/detail';
                break;
            case 'min-hotel-detail':
                url = '/culturalTravel/hotelHomestay/detail';
                break;
            case 'min-shop-detail':
                url = '/culturalTravel/shoppingPlace/detail';
                break;
            case 'min-entertainment-detail':
                url = '/culturalTravel/entertainmentVenues/detail';
                break;
            }
            return url;
        },
        goBack() {
            this.$router.go(-1);
        },
        detailModalClick() {
            if(this.modalCanHide){
                this.selectDevice = {};
                this.$refs.audio.pause();
                this.isPlayAudio = false;
                this.modalCanHide = false
                this.modalShowText=false
                console.log( this.modalCanHide,'9898',this.modalShowText)
            }
        }
            
    },
};
</script>

<style lang="scss" scoped>
$px: 1rem/37.5;

.describetion {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    width: 330px !important;
    color: #333333;
    margin: 10px 21px 0 15px;
    display: -webkit-box;
    /* 将容器以弹性盒子形式布局 */
    -webkit-line-clamp: 2;
    /* 限制文本显示为两行 */
    -webkit-box-orient: vertical;
    /* 将弹性盒子的主轴方向设置为垂直方向 */
    overflow: hidden;
    /* 隐藏容器中超出部分的内容 */
}

.serchbox {
    padding-left: 5 * $px;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 9999;
    top: 12 * $px;
    width: 162 * $px;
    height: 36 * $px;
    background: #ffffff;
    border-radius: 18 * $px;
    border: 1 * $px solid #eeeeee;
    left: 16 * $px;

    span {
        font-size: 14 * $px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-left: 3 * $px;
    }
}
.btnGroupStyle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    .detailBtn{
        width: 166px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 19px;
        border: 1px solid #CCCCCC;
        text-align: center;
        line-height: 38px;
        font-size: 16px;
        color: #333333;
    }
    .navigation{
        width: 166px;
        height: 38px;
        background: #1677FF;
        border-radius: 19px;
        text-align: center;
        line-height: 38px;
        font-size: 16px;
        color: #FFFFFF;
    }
    .navigationTwo{
        width: 343px;
        height: 38px;
        background: #1677FF;
        border-radius: 19px;
        text-align: center;
        line-height: 38px;
        font-size: 16px;
        color: #FFFFFF;
    }
}
.carportWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    margin-top: 16px;
    >div:nth-of-type(1){
        font-size: 18px;
        color: #333333;
    }
    >div:nth-of-type(2){
        font-size: 18px;
        color: #32BE33;
        font-weight: 700;
    }
}
.audioLine {
    height: 28px;
    position: relative;
    margin: 8px 6px;
    border-radius: 46px;
    border: 1px solid #cccccc;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    z-index: 99999;

    img {
        width: 24px;
        height: 24px;
        margin-left: 5px;
    }

    .playArea {
        width: 24px;
        height: 24px;
        margin-left: 5px;
    }

    .tiptitle {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin: 0 5px;
    }

    .slider {
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .audioFont {
        color: #0069ff;
        font-size: 16px;
        margin-right: 5px;
    }
}

.amap-page-container {
    height: 100%;
    position: relative;
}

.amap-demo {
    height: 100%;
}

.bottonArea {
    position: absolute;
    bottom: 1rem;
    left: 0.8rem;
    border-radius: 0.16rem;
}

.bottomLine {
    height: 168 * $px;
    background: #ffffff;
    box-shadow: 0px 2 * $px 4 * $px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8 * $px 8 * $px 0px 0px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
}

.bottom-title {
    font-size: 15 * $px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 21 * $px;
    padding: 16 * $px 13 * $px 12 * $px;
}

.bottom-icon-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16 * $px;
}

.iconArea {
    height: 95 * $px;
    width: 63 * $px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >img {
        display: block;
        width: 39 * $px;
        height: 39 * $px;
    }

    >span {
        font-size: 13 * $px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18 * $px;
        padding-top: 5 * $px;
    }
}

.iconAreaSelect {
    background: #e1f4ff;
    border-radius: 16 * $px;

    >span {
        font-size: 14 * $px;
        font-weight: bold;
        color: #333;
    }
}

.selectedFont {
    margin-top: 0.1rem;
    color: #1677ff;
}

.noselectFont {
    margin-top: 0.1rem;
    color: #999999;
}

.describetion {}

.detailTab {
    width: 8.93333rem;
    height: 1.38667rem;
    position: absolute;
    left: 0.53333rem;
    bottom: 2.1333rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
    box-shadow: 0px 0.08rem 0.16rem 0px rgba(204, 206, 210, 0.5);
    z-index: 99;
}

.detailTabParking {
    width: 8.93333rem;
    height: 1.38667rem;
    position: absolute;
    left: 0.53333rem;
    bottom: 2.1333rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
    z-index: 99;
    box-shadow: 0px 0.08rem 0.16rem 0px rgba(204, 206, 210, 0.5);
}

.parkingTab {
    height: 100%;
    width: 7.5rem;
}

.parkingTabTitleLine {
    height: 20 * $px;
    margin-top: 6 * $px;
    margin-left: 10 * $px;
    font-size: 14 * $px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.parkingTabFont {
    margin-left: 0.24rem;
    height: 16 * $px;
    width: 30 * $px;
    color: #ff7e48;
    border: #ff7e48 0.75 * $px solid;
    box-sizing: border-box;
    border-radius: 6 * $px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smallSize {
    font-size: 12px;
    -webkit-transform-origin-x: 0;
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
}

.oSmallSize {
    font-size: 9px;
}

.selectDeviceImg {
    position: absolute;
    height: 52 * $px;
    width: 52 * $px;
    left: 10 * $px;
    bottom: 10 * $px;
    box-shadow: 0px 1 * $px 2 * $px 0px rgba(243, 242, 242, 1);
}

.selectDeviceTitle {
    margin-left: 71 * $px;
    color: #3f3f3f;
    font-size: 14 * $px;
    height: 100%;
    width: 140 * $px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
}

.checkDetail {
    font-size: 10 * $px;
    color: #c0c0c0;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    overflow-x: hidden;
}

.checkDetailAndDaohang {
    height: 100%;
    width: 50 * $px;
    display: flex;
    margin-right: 8 * $px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkDetailArea {
    position: absolute;
    top: 0rem;
    right: 1.62rem;
}

.checkDaohangArea {
    position: absolute;
    top: 0.15rem;
    right: 0.16rem;
}

.parkingIconArea {
    width: 22px;
    height: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.small-font {
    font-size: 14px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.5);
}

.smallsize-font {
    font-size: 7px;
}

.fontDisplay {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.parkingDetailLine {
    height: 14 * $px;
    margin-left: 10 * $px;
    color: #989898;
    font-size: 10 * $px;
}

.detailTabArea {
    width: 120 * $px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.detailTabOutImg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DetailImg {
    width: 17 * $px;
    height: 17 * $px;
}

.parkingTab-left {
    width: 260 * $px;
    height: 100%;
}

.parkingTab-right {
    width: 70 * $px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.rightLine {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 16 * $px;
    top: 24 * $px;
    background-color: #ffffff;
    width: 40 * $px;
    box-shadow: 0 * $px 2 * $px 4 * $px 0 * $px rgba(0, 0, 0, 0.2);
    border-radius: 4 * $px;
    z-index: 99;

    .right-item {
        padding: 8 * $px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after {
            content: '';
            width: 32 * $px;
            height: 1px;
            background: #d8d8d8;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &:nth-child(4) {
            &::after {
                display: none;
            }
        }

        >img {
            display: block;
            width: 22 * $px;
            height: 22 * $px;
        }

        >span {
            font-size: 10 * $px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2f3440;
            line-height: 14 * $px;
            padding-top: 2 * $px;
        }
    }

    .right-item-select {
        background-color: #419eff;

        &::after {
            display: none;
        }

        >span {
            color: #ffffff;
        }
    }
}

.right-location {
    position: absolute;
    right: 12 * $px;
    top: 304 * $px;

    img {
        width: 46px;
        height: 56px;
    }
}

.detail-modal-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    //   bottom:0;
    left: 0;
    bottom: 0;
    z-index: 90;
    .detail-modal-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        // bottom:0;
        left: 0;
        /* background: #000; */
        z-index: 80;
    }
    .contentTop{
        /* display: flex;
        align-items: flex-start;
        justify-content: space-between; */
        /* margin-bottom: 12*$px; */
        padding: 16*$px 16*$px 0;
        box-sizing: border-box;
        .contentLeft{
            /* flex: 1; */
            /* width: 90%; */
            display: flex;
            align-items: center;
            justify-content: space-between;
            >img{
                width: 80*$px;
                height: 80*$px;
                border-radius: 2*$px;
                margin-right: 8px;
                object-fit: cover; 
            }
            .titleWrap{
                flex: 1;
                max-height: 80*$px;
                position: relative;
                .detail-title {
                    width: 260px;
                    font-size: 17px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    color: #333333;
                    line-height: 25px;
                    /* margin-left: 8px; */
                    margin-right: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .detail-titleTwo {
                    width: 250px;
                    font-size: 17px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    color: #333333;
                    line-height: 25px;
                    margin-left: 8px;
                    margin-right: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .detail-titleThree{
                    width: 90%;
                    font-size: 17px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    color: #333333;
                    line-height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .distanceWrap{
                    font-size: 12px;
                    color: #666666;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
            .titleWrapTwo{
                width: 100%;
                max-height: 80*$px;
                position: relative;
                .detail-titleThree{
                    width: 100%;
                    font-size: 17px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    color: #333333;
                    line-height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .distanceWrap{
                    font-size: 12px;
                    color: #666666;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
        .detail-close {
            width: 32px;
            height: 24px;
            background: url(https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E5%AF%BC%E8%A7%88%E5%85%B3%E9%97%AD%E6%8C%89%E9%92%AE.png) no-repeat;
            background-size: 100% 100%;
        }
    }
}

.detail-modal {
    box-shadow: 0 * $px 2 * $px 4 * $px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8 * $px 8 * $px 0 0;
    width: 375 * $px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    padding-bottom: 16 * $px;

    .title-wrap {
        margin: 0 16 * $px 16 * $px;
        margin-bottom: 0px;
        padding-top: 8 * $px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .detail-title {
            /* width: 289px; */
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            color: #333333;
            line-height: 25px;
            margin-left: 8px;
            margin-right: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .detail-close {
            background: url(https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%96%B0%E6%A0%87%E5%87%86%E6%96%87%E6%97%85/%E5%9C%B0%E5%9B%BE%E5%AF%BC%E8%A7%88%E5%85%B3%E9%97%AD%E6%8C%89%E9%92%AE.png) no-repeat;
            background-size: 100% 100%;
            width: 32 * $px;
            height: 24 * $px;
        }
    }

    .detail-address {
        margin: 0 16px 16px;
        align-items: center;
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 12px;
        .distance {
            width: 100px;
            /* margin-left: 8px; */
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }

        .addressTitle {
            display: inline-block;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-left: 4px;
            width: 240px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        img {
            width: 25px;
            height: 25px;
        }
    }

    .detail-desc-wrap {
        padding: 0 32px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon-location {
            display: block;
            width: 22px;
            height: 24px;
        }

        .icon-arrow {
            display: block;
            width: 18px;
            height: 18px;
        }

        >p {
            margin: 0 8px;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #999999;
            line-height: 20px;
            flex: 1;
        }
    }

    .detail-pic-wrap {
        height: 93px;
        margin: 0 24px;

        >img {
            width: 107px;
            height: 93px;
            border-radius: 4px;
        }
    }

    .detail-parking-desc {
        font-size: 16 * $px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #333333;
        line-height: 24 * $px;
        text-indent: 24 * $px;
        padding: 4 * $px 0;
    }

    .go-location {
        width: 327 * $px;
        height: 49 * $px;
        background: #1777ff;
        border-radius: 25 * $px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18 * $px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 25 * $px;
        margin: 10 * $px auto;
    }
}
</style>
<style>
.amap-icon img {
    width: 30px;
    height: 30px;
}

.amap-overlay-text-container {
    font-size: 0.4rem;
}

.amap-logo,
.amap-copyright {
    display: none !important;
}

.amap-geo {
    display: none;
}
</style>
  